import {Component} from "javascripts/lib/component";
import "../application";

// _global bootstrap
window.bootstrap = require('bootstrap');

export class RedeemLayout extends Component {
  didLoad() {
    // Nothing to do
  }
}
